import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { dateFilter } from "vue-date-fns";
import VueGoogleMaps from '@fawmi/vue-google-maps'

let app = createApp(App);

app.config.globalProperties.$filters = {
    dateFilter
}

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAml1C-ULhq-k_xmrNJIi90iNx3NFdFbdI',
        // language: 'de',
    },
});

app.use(router).mount('#app');