<template>
  <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="w-[150px] mx-auto" src="./../assets/logo.svg" alt="kreativ mobil." />
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
      <div class="bg-white py-8 px-8 shadow-lg border border-gray-300 rounded-lg sm:px-10">
        <div class="space-y-4">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">E-Mail-Adresse</label>
            <div class="mt-1">
              <input id="email" v-model="this.email" name="email" type="email" autocomplete="email" required="" class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-pink-500 focus:outline-none focus:ring-pink-500 sm:text-sm" />
            </div>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">Passwort</label>
            <div class="mt-1">
              <input id="password" v-model="this.password" name="password" type="password" autocomplete="current-password" required="" class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-pink-500 focus:outline-none focus:ring-pink-500 sm:text-sm" />
            </div>
          </div>

          <div v-if="this.errormsg.length > 0" class="my-6 text-center text-pink-600">
            {{ this.errormsg }}
          </div>

          <div>
            <div @click="this.submitLogin()" class="flex cursor-pointer w-full mt-6 justify-center rounded-md border border-transparent bg-pink-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-pink-700 focus:outline-none">Login</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
//import Router from './../router';

export default {
  name: 'LoginForm',
  props: {
  },
  data() {
    return {
      email: '',
      password: '',
      errormsg: ''
    }
  },
  methods: {
    submitLogin() {
      axios
        .get('/api/auth/checklogin?email=' + this.email + '&password=' + this.password)
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          this.errormsg = 'Zugangsdaten falsch'
        });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
