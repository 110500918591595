<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  computed: {
  },
}
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
