<template>
  <div v-if="typeof loggedIn != 'undefined'" class="w-[90vw] max-w-[1140px] mx-auto">
    <LoginForm v-if="!loggedIn" />
    <BackendArea :site="this.site" v-if="loggedIn" />
  </div>
</template>

<script>
import LoginForm from '../components/LoginForm.vue';
import BackendArea from '../components/BackendArea.vue';
import axios from "axios";
export default {
  name: 'AdminArea',
  components: {
    LoginForm,
    BackendArea
  },
  props: {
    site: String,
  },
  data() {
    return {
      loggedIn: undefined
    }
  },
  methods: {
    checkSession() {
      axios
          .get('/api/auth/checksession')
          .then((res) => {
            this.loggedIn = res.data;
          })
          .catch(() => {
            this.loggedIn = false;
          });
    }
  },
  computed: {
    currentSite() {
      let self = this;
      return self.site;
    }
  },
  beforeMount() {
    this.checkSession();
  }
}
</script>

<style>
</style>
