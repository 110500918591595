import {
    createWebHistory,
    createRouter
} from "vue-router";

import AdminArea from './pages/AdminArea.vue';
import WebsiteFrontend from './pages/WebsiteFrontend.vue';

const routes = [{
    path: '/',
    component: WebsiteFrontend,
    name: 'homepage',
    props: {
        site: 'homepage',
    }
    },
    {
        path: '/werke/eigene',
        component: WebsiteFrontend,
        name: 'eigene-werke',
        props: {
            site: 'eigene-werke',
        }
    },
    {
        path: '/werke/teilnehmer',
        component: WebsiteFrontend,
        name: 'teilnehmer-werke',
        props: {
            site: 'teilnehmer-werke',
        }
    },
    {
        path: '/about',
        component: WebsiteFrontend,
        name: 'about',
        props: {
            site: 'about',
        }
    },
    {
        path: '/shop',
        component: WebsiteFrontend,
        name: 'shop',
        props: {
            site: 'shop',
        }
    },
    {
        path: '/impressum',
        component: WebsiteFrontend,
        name: 'impressum',
        props: {
            site: 'impressum',
        }
    },
    {
        path: '/admin',
        component: AdminArea,
        name: 'backend-login',
        props: {
            site: 'backend-login',
        }
    },
    {
        path: '/admin/termine',
        component: AdminArea,
        name: 'backend-dates',
        props: {
            site: 'backend-dates',
        }
    },
    {
        path: '/admin/werke/eigene',
        component: AdminArea,
        name: 'backend-ownpics',
        props: {
            site: 'backend-ownpics',
        }
    },
    {
        path: '/admin/werke/teilnehmer',
        component: AdminArea,
        name: 'backend-memberpics',
        props: {
            site: 'backend-memberpics',
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                top: document.querySelector('.main-header').offsetHeight
            }
        }
    },
});

export default router;