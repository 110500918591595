<template>
  <div v-if="this.courses">
    <div class="flex flex-col mx-6 my-6 border border-gray-300 rounded-md overflow-hidden">
      <div class="-my-2 overflow-x-auto">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">Kurstitel</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Nächster Termin</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Termin</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Freie Plätze</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Adresse</th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
              <tr class="align-middle" v-for="(course, index) in this.courses" :key="index">
                <td class="block w-[225px] py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">{{ course.title }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><div v-html="course.nextDate"></div></td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500" style="white-space: pre-line;"><span class="flex items-center space-x-2 leading-[1]"><span class="text-lg">{{ course.currentDate }}{{course.dates.length}}</span> <span class="text-xs leading-[.9]">{{ course.currentDate == '' ? course.dates.length > 1 ? 'Termine\n vergangen' : 'Termin\n vergangen' : '' }}</span></span></td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ course.places.available }} / {{ course.places.total }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500" v-html="course.address.split(',').join('<br />')"></td>
                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                  <span class="flex items-center space-x-2">
                    <span @click="this.showModal(index)"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="#0369a1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="cursor-pointer icon icon-tabler icon-tabler-edit"><path stroke="none" d="M0 0h24v24H0z"/><path d="M7 7H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-1"/><path d="M20.385 6.585a2.1 2.1 0 0 0-2.97-2.97L9 12v3h3l8.385-8.415zM16 5l3 3"/></svg></span>
                    <span @click="this.deleteCourse(index)"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="#be185d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="cursor-pointer icon icon-tabler icon-tabler-trash"><path stroke="none" d="M0 0h24v24H0z"/><path d="M4 7h16M10 11v6M14 11v6M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M9 7V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3"/></svg></span>
                  </span>
                </td>
                <div v-if="course.modal" @click="course.modal = false" class="fixed w-full h-full bg-[rgba(0,0,0,.6)] cursor-pointer backdrop-blur-lg top-0 left-0 right-0 bottom-0 z-20"></div>
                <div v-if="course.modal" style="backface-visibility: hidden; transform: translateX(-50%)" class="fixed bg-white rounded-lg shadow-2xl overflow-y-auto p-6 w-[90vw] max-w-[1140px] z-50 max-h-[80vh] left-[50%] top-[10vh]">
                  <div class="sm:flex mb-4 items-center sm:justify-between">
                    <div class="flex mt-0 text-center sm:text-left">
                      <p class="text-xl font-bold text-gray-900 sm:text-2xl sm:leading-[.75]">Kurs Aktualisieren</p>
                    </div>
                    <div class="flex mt-0 hidden sm:flex justify-center">
                      <img class="mx-auto h-auto w-16" src="./../assets/logo.svg" alt="" />
                    </div>
                  </div>
                  <DateForm @saveDate="this.submitDate('update', course); course.modal = false" @update:modelValue="(modelData) => { this.courses[index] = modelData }" v-model="this.courses[index]" :type="'update'" />
                </div>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end pb-6 px-6">
      <button type="button" @click="this.newCourse = true" class="inline-flex items-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base tracking-wide font-light text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Neuen Kurs anlegen</button>
      <div v-if="this.newCourse" @click="this.newCourse = false" class="fixed w-full h-full bg-[rgba(0,0,0,.6)] cursor-pointer backdrop-blur-lg top-0 left-0 right-0 bottom-0 z-20"></div>
      <div v-if="this.newCourse" style="backface-visibility: hidden; transform: translateX(-50%)" class="fixed bg-white rounded-lg shadow-2xl overflow-y-auto p-6 w-[90vw] max-w-[1140px] z-50 max-h-[80vh] left-[50%] top-[10vh]">
        <div class="sm:flex mb-4 items-center sm:justify-between">
          <div class="flex mt-0 text-center sm:text-left">
            <p class="text-xl font-bold text-gray-900 sm:text-2xl sm:leading-[.75]">Kurs Erstellen</p>
          </div>
          <div class="flex mt-0 hidden sm:flex justify-center">
            <img class="mx-auto h-auto w-16" src="./../assets/logo.svg" alt="" />
          </div>
        </div>
        <DateForm @saveDate="this.submitDate('create', this.newDate); this.newCourse = false" @update:modelValue="(modelData) => { this.newDate = modelData }" v-model="this.newDate" :type="'create'" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import DateForm from './DateForm.vue';
Date.prototype.getDayName = function() {
  let days = ['So','Mo','Di','Mi','Do','Fr','Sa'];
  return days[ this.getDay() ];
};

export default {
  name: 'DatesView',
  components: {
    DateForm
  },
  props: {
    site: String
  },
  data() {
    return {
      courses: false,
      newCourse: false,
      newDate: {
        title: 'Test',
        description: '',
        infotext: '',
        address: '',
        price: '',
        places: {
          total: 1,
          available: 1
        },
        registration: {
          online: '',
          phone: '0157 73194112'
        },
        dates: [
          {
            start: new Date(new Date(new Date().setDate(new Date().getDate() + 30)).setHours(10)).setMinutes(0),
            end: new Date(new Date(new Date().setDate(new Date().getDate() + 30)).setHours(13)).setMinutes(15)
          }
        ],
        dateinfo: ''
      }
    }
  },
  methods: {
    submitDate(type, obj) {
      axios
          .post('/api/courses/' + type, obj)
          .then(() => {
            if(type == 'create') {
              obj.index = this.courses.length;
              this.courses.push(obj);
              this.renderNextDate(obj.index);
            } else {
              this.renderNextDate(obj.index);
            }
          })
          .catch(() => {
          });
    },
    getCourses() {
      axios
          .get('/api/courses/get/all')
          .then((res) => {
            this.courses = res.data;
            this.$emit('pushCourses', res.data.length);
            this.courses.forEach((it, ind) => {
              it.index = ind;
              this.renderNextDate(ind);
            });
          })
          .catch(() => {
          });
    },
    deleteCourse(index) {
      if(window.confirm('Sicher, dass Du diesen Kurs löschen möchtest?')) {
        axios
            .get('/api/courses/delete?id=' + this.courses[index]._id)
            .then(() => {
              this.courses.splice(index, 1);
            })
            .catch(() => {
            });
      }
    },
    renderNextDate(index) {
      let today = new Date();
      async function callDateRender(courses, formatDate) {
        return await new Promise(resolve => {
          courses[index].dates.forEach((date, ind) => {
            let dateob = new Date(date.start);
            let dateStr = formatDate(dateob);
            if (dateob >= today) {
              let retString = `<span>${dateStr}</span>`;
              resolve([retString, (parseInt(ind) + 1) + ' / ']);
            }
            if ((ind + 1) == courses[index].dates.length) {
              let retString = `<span class="text-pink-600">Alle Termine vergangen</span>`;
              resolve([retString, '']);
            }
          });
        });
      }
      callDateRender(this.courses, this.formatDate).then((ret) => {
        this.courses[index].nextDate = ret[0];
        this.courses[index].currentDate = ret[1];
        this.newDate.index = this.courses.length;
      });
    },
    showModal(index) {
      this.courses[index].modal = true;
    },
    formatDate(date) {
      return String(date.getDayName()) + ', ' + String(date.getDate()).padStart(2, '0') + '.' + String(date.getMonth() + 1).padStart(2, '0') + '.' + String(date.getFullYear()).slice(-2) + ' ' + String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0');
    },
    setHours(dt, h) {
      var s = /(\d+):(\d+)(.+)/.exec(h);
      dt.setHours(s[3] === "pm" ?
          12 + parseInt(s[1], 10) :
          parseInt(s[1], 10));
      dt.setMinutes(parseInt(s[2],10));
      return dt;
    }
  },
  mounted() {
    this.getCourses();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
