<template>
  <div class="border border-gray-300 rounded-lg w-full my-8 shadow-lg overflow-hidden">
    <div class="bg-white p-6">
      <div class="sm:flex sm:items-center sm:justify-between">
        <div class="sm:flex items-center sm:space-x-5">
          <div class="flex-shrink-0">
            <img class="mx-auto h-20 w-20 rounded-full shadow-md" src="./../assets/gmacke.jpg" alt="" />
          </div>
          <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
            <p class="text-sm font-medium text-gray-600">Willkommen zurück,</p>
            <p class="text-xl font-bold text-gray-900 sm:text-2xl">Gabriela Macke</p>
          </div>
        </div>
        <div class="mt-5 hidden sm:flex justify-center sm:mt-0">
          <img class="mx-auto h-auto w-24" src="./../assets/logo.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 border-t border-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-x">
      <div @click="this.$router.push('/admin/termine')" class="px-6 py-3 transition-all duration-700 border-b-[3px] border-b-gray-400 hover:bg-gray-200 cursor-pointer text-gray-700 text-center text-lg font-light" :class="{ 'border-b-pink-600' : this.currentSite == 'backend-dates', 'opacity-50' : this.currentSite != 'backend-dates' && this.currentSite != 'backend-login'}" >
        <span>Termine</span>
      </div>
      <div @click="this.$router.push('/admin/werke/eigene')" class="px-6 py-3 transition-all duration-700 border-b-[3px] border-b-gray-400 hover:bg-gray-200 cursor-pointer text-gray-700 text-center text-lg font-light" :class="{ 'border-b-pink-600' : this.currentSite == 'backend-ownpics', 'opacity-50' : this.currentSite != 'backend-ownpics' && this.currentSite != 'backend-login'}">
        <span class="text-gray-600">Eigene Werke</span>
      </div>
      <div @click="this.$router.push('/admin/werke/teilnehmer')" class="px-6 py-3 transition-all duration-700 border-b-[3px] border-b-gray-400 hover:bg-gray-200 cursor-pointer text-gray-700 text-center text-lg font-light" :class="{ 'border-b-pink-600' : this.currentSite == 'backend-memberpics', 'opacity-50' : this.currentSite != 'backend-memberpics' && this.currentSite != 'backend-login'}">
        <span class="text-gray-600">Werke der Teilnehmer</span>
      </div>
    </div>

    <DatesView @pushCourses="(total) => {this.total.courses = total}" v-if="this.currentSite == 'backend-dates'" />
    <MediaCenter :site="this.site" v-if="this.currentSite == 'backend-ownpics' || this.currentSite == 'backend-memberpics'" />

  </div>
</template>

<script>
import axios from 'axios';
import DatesView from "./DatesView";
import MediaCenter from "./MediaCenter";
//import Router from './../router';

export default {
  name: 'BackendArea',
  components: {
    DatesView,
    MediaCenter
  },
  props: {
    site: String
  },
  data() {
    return {
      total: {
        courses: 0
      }
    }
  },
  methods: {
    submitLogin() {
      axios
          .get('/api/auth/checklogin?email=' + this.email + '&password=' + this.password)
          .then(() => {
            window.location.reload();
          })
          .catch(() => {
            this.errormsg = 'Zugangsdaten falsch'
          });
    }
  },
  computed: {
    currentSite() {
      let self = this;
      return self.site;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
