<template>
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-start gap-y-4 md:gap-x-4">
    <div class="relative border border-gray-300 rounded-md shadow-sm pt-6 focus-within:ring-1 focus-within:ring-pink-600">
      <div class="absolute top-0 left-0 w-full px-3 pt-1.5 pb-1 text-xs bg-gray-100 font-bold text-gray-600">Kurstitel*</div>
      <input class="w-full rounded-b-md pt-1.5 pb-1 px-3 text-sm border-0 text-sm focus:border-0 focus:ring-0" type="text" placeholder="Kurstitel" v-model="this.content.title" @input="this.handleInput" />
    </div>
    <div class="relative border border-gray-300 rounded-md shadow-sm pt-6 focus-within:ring-1 focus-within:ring-pink-600">
      <div class="absolute top-0 left-0 w-full px-3 pt-1.5 pb-1 text-xs bg-gray-100 font-bold text-gray-600">Kursbeschreibung*</div>
      <textarea rows="1" style="-webkit-font-smoothing: antialiased;" class="w-full min-h-[30px] pt-1.5 pb-1 px-3 mb-[-6px] text-sm border-0 text-sm focus:border-0 focus:ring-0" type="text" placeholder="Kursbeschreibung" v-model="this.content.description" @input="this.handleInput">
      </textarea>
    </div>
    <div class="relative border border-gray-300 rounded-md shadow-sm pt-6 focus-within:ring-1 focus-within:ring-pink-600">
      <div class="absolute top-0 left-0 w-full px-3 pt-1.5 pb-1 text-xs bg-gray-100 font-bold text-gray-600">Preis*</div>
      <input class="w-full rounded-b-md pt-1.5 pb-1 px-3 text-sm border-0 text-sm focus:border-0 focus:ring-0" type="text" placeholder="z.B. 17,- €" v-model="this.content.price" @input="this.handleInput" />
    </div>
    <div class="relative border border-gray-300 rounded-md shadow-sm pt-6 focus-within:ring-1 focus-within:ring-pink-600">
      <div class="absolute top-0 left-0 w-full px-3 pt-1.5 pb-1 text-xs bg-gray-100 font-bold text-gray-600">Adresse*</div>
      <input class="w-full rounded-b-md pt-1.5 pb-1 px-3 text-sm border-0 text-sm focus:border-0 focus:ring-0" type="text" placeholder="Adresse" v-model="this.content.address" @input="this.handleInput" />
    </div>
    <div class="relative border border-gray-300 rounded-md shadow-sm pt-6 focus-within:ring-1 focus-within:ring-pink-600">
      <div class="absolute top-0 left-0 w-full px-3 pt-1.5 pb-1 text-xs bg-gray-100 font-bold text-gray-600">Plätze gesamt</div>
      <input class="w-full rounded-b-md pt-1.5 pb-1 px-3 text-sm border-0 text-sm focus:border-0 focus:ring-0" type="text" placeholder="z.B. 10" v-model="this.content.places.total" @input="this.handleInput" />
    </div>
    <div class="relative border border-gray-300 rounded-md shadow-sm pt-6 focus-within:ring-1 focus-within:ring-pink-600">
      <div class="absolute top-0 left-0 w-full px-3 pt-1.5 pb-1 text-xs bg-gray-100 font-bold text-gray-600">Plätze verfügbar</div>
      <input class="w-full rounded-b-md pt-1.5 pb-1 px-3 text-sm border-0 text-sm focus:border-0 focus:ring-0" type="text" placeholder="z.B. 3" v-model="this.content.places.available" @input="this.handleInput" />
    </div>
    <div class="relative border border-gray-300 rounded-md shadow-sm pt-6 focus-within:ring-1 focus-within:ring-pink-600">
      <div class="absolute top-0 left-0 w-full px-3 pt-1.5 pb-1 text-xs bg-gray-100 font-bold text-gray-600">Anmeldungs Link</div>
      <input class="w-full rounded-b-md pt-1.5 pb-1 px-3 text-sm border-0 text-sm focus:border-0 focus:ring-0" type="text" placeholder="https://xyz.xyz" v-model="this.content.registration.online" @input="this.handleInput" />
    </div>
    <div class="relative border border-gray-300 rounded-md shadow-sm pt-6 focus-within:ring-1 focus-within:ring-pink-600">
      <div class="absolute top-0 left-0 w-full px-3 pt-1.5 pb-1 text-xs bg-gray-100 font-bold text-gray-600">Anmeldungs Telefon</div>
      <input class="w-full rounded-b-md pt-1.5 pb-1 px-3 text-sm border-0 text-sm focus:border-0 focus:ring-0" type="text" placeholder="0157 ..." v-model="this.content.registration.phone" @input="this.handleInput" />
    </div>
    <div class="relative border border-gray-300 rounded-md shadow-sm pt-6 focus-within:ring-1 focus-within:ring-pink-600">
      <div class="absolute top-0 left-0 w-full px-3 pt-1.5 pb-1 text-xs bg-gray-100 font-bold text-gray-600">Zusatzinfo Beschreibung</div>
      <textarea rows="1" style="-webkit-font-smoothing: antialiased;" class="w-full min-h-[30px] pt-1.5 pb-1 px-3 mb-[-6px] text-sm border-0 text-sm focus:border-0 focus:ring-0" type="text" placeholder="Zusatz Info..." v-model="this.content.infotext" @input="this.handleInput">
      </textarea>
    </div>
    <div class="relative border border-gray-300 rounded-md shadow-sm pt-6 focus-within:ring-1 focus-within:ring-pink-600">
      <div class="absolute flex justify-between items-center top-0 left-0 w-full px-3 pt-1.5 pb-1 text-xs bg-gray-200 font-bold text-gray-600">
        <span>Termine</span>
        <svg @click="this.content.dates.push({ ...this.content.dates[this.content.dates.length - 1] }); this.handleInput" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="#16a34a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="absolute right-3 top-[50%] translate-y-[-50%] cursor-pointer icon icon-tabler icon-tabler-plus" viewBox="0 0 24 24"><path stroke="none" d="M0 0h24v24H0z"/><path d="M12 5v14M5 12h14"/></svg>
      </div>
      <div v-for="(date, index) in this.content.dates" :key="index" class="border border-b-gray-300 shadow-md">
        <div class="w-full flex justify-start px-[4px] pt-[1.65rem] relative overflow-hidden">
          <div class="absolute top-0 left-0 right-0 px-3 pt-1.5 pb-1 text-xs bg-gray-100 text-gray-700">
            <span>{{ 'Termin ' + (index + 1) }}</span>
            <svg @click="this.content.dates.splice(index, 1); this.handleInput" v-if="index != 0" xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" stroke="#9d174d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="absolute right-3 top-[50%] translate-y-[-50%] cursor-pointer icon icon-tabler icon-tabler-trash" viewBox="0 0 24 24"><path stroke="none" d="M0 0h24v24H0z"/><path d="M4 7h16M10 11v6M14 11v6M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M9 7V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3"/></svg>
          </div>
          <Datepicker :uid="'dps' + index" @update:modelValue="(modelData) => { this.content.dates[index].end = new Date(+ new Date(modelData) + parseInt(3.25 * 60 * 60 * 1000)); this.handleInput; }" :format="this.formatDate" v-model="this.content.dates[index].start">
            <template #input-icon>
              <span>Start: </span>
            </template>
          </Datepicker>
          <Datepicker :uid="'dpe' + index" @update:modelValue="this.handleInput" :format="this.formatDate" v-model="this.content.dates[index].end">
            <template #input-icon>
              <span>Ende: </span>
            </template>
          </Datepicker>
        </div>
      </div>
    </div>
    <div class="relative border border-gray-300 rounded-md shadow-sm pt-6 focus-within:ring-1 focus-within:ring-pink-600">
      <div class="absolute top-0 left-0 w-full px-3 pt-1.5 pb-1 text-xs bg-gray-100 font-bold text-gray-600">Zusatzinfo Termine</div>
      <input class="w-full rounded-b-md pt-1.5 pb-1 px-3 text-sm border-0 text-sm focus:border-0 focus:ring-0" type="text" placeholder="Termine für die TS ..." v-model="this.content.dateinfo" @input="this.handleInput" />
    </div>
    <div @click="this.inputValid ? this.$emit('saveDate', this.type) : '';" class="min-h-[56px] px-4 py-2 bg-gray-100 hover:bg-slate-300 text-slate-700
    border border-gray-300 rounded-md cursor-pointer shadow-sm text-md flex items-center justify-center" :class="{ 'opacity-50 hover:bg-gray-100 cursor-not-allowed' : !this.inputValid}">
      <span>{{ !this.inputValid ? 'Pflichtfelder ausfüllen' : this.type == 'create' ? 'Kurs anlegen' : 'Kurs speichern' }}</span>
    </div>
  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
Date.prototype.getDayName = function() {
  let days = ['So','Mo','Di','Mi','Do','Fr','Sa'];
  return days[ this.getDay() ];
};

export default {
  name: 'DateForm',
  components: {
    Datepicker
  },
  props: {
    modelValue: Object,
    type: String
  },
  emits: ['update:modelValue'],
  data() {
    return {
      content: this.modelValue
    }
  },
  methods: {
    formatDate(date) {
      return String(date.getDayName()) + ', ' + String(date.getDate()).padStart(2, '0') + '.' + String(date.getMonth() + 1).padStart(2, '0') + '.' + String(date.getFullYear()).slice(-2) + ' ' + String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0');
    },
    handleInput() {
      this.$emit('update:modelValue', this.content);
    }
  },
  computed: {
    inputValid() {
      return (this.content.title.length) && (this.content.title.length) && (this.content.description.length) && (this.content.address.length)
          && (this.content.price.length) && (this.content.registration.online.length || this.content.registration.phone.length)
          && (typeof this.content.dates[0] != 'undefined');
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.dp__input {
  border: 0;
  font-size: .8rem;
  padding: 2px .5rem 2px 45px;
}
.dp__input_icon {
  font-size: .8rem;
  color: #333;
  padding: 0 0.5rem;
}
.dp__clear_icon {
  display: none;
}
</style>
