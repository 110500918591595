<template>
  <div v-if="this.site && this.files != 'undefined' && typeof isDragActive != 'undefined' || this.unlock" class="flex flex-col mx-6 my-6">
    <div v-bind="getRootProps()" @click="this.$refs.dropInput.click()" tabindex="-1" class="relative flex hover:cursor-pointer items-center justify-center min-h-[80px] rounded-lg border-2 border-dashed border-gray-300 px-[1vw] py-[7px] my-4 mx-0 sm:my-0 text-center hover:border-gray-400 focus:outline-none focus:border-pink-600" :class="{ 'border-pink-600' : isDragActive }">
      <div class="absolute top-0 left-0 right-0 block h-[3px] text-xs bg-gray-100"><div :style="{ width: this.uploadPercentage + '%' }" class="absolute ease-in-out duration-500 top-0 bottom-0 left-0 bg-pink-600"></div></div>
      <svg xmlns="http://www.w3.org/2000/svg" v-if="!this.validationError" width="28" height="28" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="icon icon-tabler icon-tabler-drag-drop min-h-[20px] min-w-[20px] text-gray-400" viewBox="0 0 24 24"><path stroke="none" d="M0 0h24v24H0z"/><path d="M19 11V9a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2"/><path d="m13 13 9 3-4 2-2 4-3-9M3 3v.01M7 3v.01M11 3v.01M15 3v.01M3 7v.01M3 11v.01M3 15v.01"/></svg>
      <svg xmlns="http://www.w3.org/2000/svg" v-if="this.validationError" width="28" height="28" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="icon icon-tabler icon-tabler-file-alert min-h-[20px] min-w-[20px] text-gray-400"><path stroke="none" d="M0 0h24v24H0z"/><path d="M14 3v4a1 1 0 0 0 1 1h4"/><path d="M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2zM12 17h.01M12 11v3"/></svg>
      <input v-if="!this.currentlyUploading" id="dropzoneinput" ref="dropInput" multiple="true" class="hidden" type='file' name='file' :disabled="this.currentlyUploading" @change="this.submitFiles($event)" />
      <div class="flex flex-col max-w-[325px] text-left leading-[1.25]">
        <span v-if="isDragActive" class="ml-2 block text-sm font-medium text-gray-900">Ziehe die Dateien hier hin...</span>
        <span v-else class="ml-2 block text-sm font-medium" :class="{ 'text-emerald-600': this.uploadSuccess && !this.validationError, 'text-gray-600': !this.uploadSuccess && !this.validationError, 'text-rose-600': this.validationError && !this.uploadSuccess }">{{ this.currentlyUploading ? this.currentFilesForUpload + ' Datei' + (currentFilesForUpload == 1 ? ' wird hochgeladen' : 'en werden hochgeladen') : ( this.validationError ? 'Upload fehlgeschlagen' : (this.uploadSuccess ? 'Upload erfolgreich' : 'Datei/en hochladen'))}}</span>
        <span v-if="this.validationMessage.length > 0 && !this.uploadSuccess" v-html="this.validationMessage" :class="{'max-w-[185px]': this.validationError }" class="text-xs ml-2 leading-[1.25]"></span>
      </div>
    </div>
    <div style="grid-auto-flow: dense;" class="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
      <div v-if="typeof this.filesearch.term != 'undefined' && this.filesTotal >= 1" class="px-3 py-2 flex items-center justify-center text-sm font-medium">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="#0d9488" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="mr-2 icon icon-tabler icon-tabler-input-search"><path stroke="none" d="M0 0h24v24H0z"/><path d="M19 11V8a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h5"/><circle cx="15.5" cy="15.5" r="2.5"/><path d="M17.5 17.5 20 20"/></svg>
        <div class="flex flex-col items-start">
                <span class="text-gray-900 -mt-[2px]">
                  Freitextsuche
                </span>
                <span class="text-xs text-gray-400">
                  <div class="inputwrap">
                    <input v-model="this.filesearch.term" type="text" placeholder="Titel oder Maler..." spellcheck="false" @keyup.prevent="this.keyUpSearch($event)" class="trueinput border-1 border-gray-300 rounded-md text-xs py-0 px-2 w-[125px] focus:border-[#0d9488]" />
                  </div>
                </span>
        </div>
      </div>
      <div v-if="this.filesearch?.daterange?.length != 0 && this.filesTotal >= 1" class="px-3 py-2 flex items-center justify-center text-sm font-medium">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="#0d9488" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="mr-2 icon icon-tabler icon-tabler-input-search"><path stroke="none" d="M0 0h24v24H0z"/><path d="M19 11V8a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h5"/><circle cx="15.5" cy="15.5" r="2.5"/><path d="M17.5 17.5 20 20"/></svg>
        <div class="flex flex-col items-start">
                <span class="text-gray-900 -mt-[2px]">
                  Datum des Uploads
                </span>
                <span class="text-xs text-gray-400">
                  <Datepicker :day-class="this.getDayClass" :min-date="new Date(this.uploadDates[0].date).setDate(new Date(this.uploadDates[0].date).getDate() - 2)" :max-date="new Date(this.uploadDates[this.uploadDates.length - 1].date).setDate(new Date(this.uploadDates[this.uploadDates.length - 1].date).getDate() + 2)" @update:modelValue="(modelData) => { this.filesearch.daterange = modelData; this.getFiles(false, 0, 50); }" :start-time="[{ hours: 0, minutes: 0, seconds: 0 }, { hours: 23, minutes: 59, seconds: 59 }]" range :model-type="'timestamp'" :format="this.formatDateRange" v-model="this.filesearch.daterange"></Datepicker>
                </span>
        </div>
      </div>
      <div v-if="this.filesTotal >= 1" class="px-3 py-2 flex items-center justify-center text-sm font-medium">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="#0d9488" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="mr-2 icon icon-tabler icon-tabler-device-sd-card"><path stroke="none" d="M0 0h24v24H0z"/><path d="M7 21h10a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-6.172a2 2 0 0 0-1.414.586L5.586 7.414A2 2 0 0 0 5 8.828V19a2 2 0 0 0 2 2zM13 6v2M16 6v2M10 7v1"/></svg>
        <div class="flex flex-col items-start">
                <span class="text-gray-900">
                  {{ this.filesTotal }} Datei{{this.filesTotal == 1 ? '': 'en'}}
                </span>
                <span class="text-xs text-gray-400">
                  {{ this.calcSize(this.memoryUsed) }}
                </span>
        </div>
      </div>
    </div>
    <div v-if="this.imageEditorActive" class="image-editor fixed z-50 w-full h-full top-0 left-0 right-0 bottom-0">
      <div v-if="this.files && this.files.length > 0" class="fixed z-30 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[95vw] h-[95vh] max-w-[1200px]">
        <div id="tui-image-editor"></div>
      </div>
      <div @click="this.imageEditorActive = !this.imageEditorActive" class="fixed z-10 cursor-pointer w-full h-full top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,.75)]"></div>
    </div>
    <div role="list" class="mt-3 flex flex-wrap justify-start sm:mx-[-1%] xl:mx-[-0.6666%]">
      <div v-for="(file, ind) in files" :key="file.updatedAt" :class="{'break': typeof file.break != 'undefined', 'my-2 flex flex-col justify-end items-stretch w-[100%] sm:w-[48%] xl:w-[32%]': typeof file.break == 'undefined', 'sm:mx-[1%] xl:mx-[0.6666%]': true}">
        <div v-if="typeof file.break != 'undefined'" class="w-full flex items-center justify-between my-3 text-lg">
          <span>{{ file.breakContent }}</span> <span class="hidden sm:flex">{{ ind == 0 ? 'Insgesamt ' + (this.filesTotalSearch == 1 ? '1 Datei gefunden' : this.filesTotalSearch + ' Dateien gefunden') : ''}}</span>
        </div>
        <div v-if="typeof file.break == 'undefined'">
          <div class="flex border-gray-200 border rounded-md shadow-sm filetile">
            <div :key="file.updatedAt" :style="'background-image: url(/files/' + file._id + '/thumbnail?pid=' + file.pid + (typeof file.updated != 'undefined' ? '?' + file.updated + ')' : ')')" :class="['bg-cover bg-center relative flex-shrink-0 flex items-center justify-center w-16 sm:w-[5.5rem] text-white text-sm font-medium rounded-l-md']">
              <div v-if="typeof file.isnew != 'undefined' || typeof file.updated != 'undefined'" class="absolute top-0 left-0 right-0 py-0 px-2 bg-pink-600 text-white text-xs text-center rounded-tl-md">{{ typeof file.isnew != 'undefined' ? 'Neue Datei' : 'Gespeichert' }}</div>
            </div>
            <div class="flex flex-1 items-start justify-between truncate rounded-r-md border-gray-200 border-l bg-white">
              <div class="flex-1 truncate pl-4 pt-2 pb-2 pr-2 text-sm">
                <p class="w-full flex inputwrap"><span class="trueinput mr-1.5">Titel: </span><input autocomplete="off" placeholder="z.B. Schwanensee" spellcheck="false" type="text" name="filetitle" data-lpignore="true" v-model="file.title" class="trueinput rounded-lg transition w-full text-xs px-1 py-[1px] text-gray-800 border-gray-200 focus:text-black focus:border-gray-400" /></p>
                <p class="w-full flex inputwrap mt-1"><span class="trueinput mr-1.5">Maler/in: </span><input autocomplete="off" placeholder="z.B. Maria M." spellcheck="false" type="text" name="filetitle" data-lpignore="true" v-model="file.author" class="trueinput rounded-lg transition w-full text-xs px-1 py-[1px] text-gray-800 border-gray-200 focus:text-black focus:border-gray-400" /></p>
                <hr class="mt-2 mb-1 border-gray-400" />
                <p class="text-gray-500 text-xs">{{ renderFileDate(file.updatedAt) }} | {{ this.calcSize(file.size) }}</p>
              </div>
              <div class="flex flex-col h-full justify-between pr-[.4rem] py-[.5rem]">
                <button @click="this.initImageEditor('/files/' + file._id + '/original', file._id, /[^/]*$/.exec(file.path.original)[0], ind)" type="button" class="inline-flex h-5 w-5 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="icon icon-tabler icon-tabler-external-link"><path stroke="none" d="M0 0h24v24H0z"/><path d="M11 7H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-5M10 14 20 4M15 4h5v5"/></svg>
                </button>
                <button @click="this.saveFile(file._id, file);" type="button" class="inline-flex h-5 w-5 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="icon icon-tabler icon-tabler-device-floppy"><path stroke="none" d="M0 0h24v24H0z"/><path d="M6 4h10l4 4v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2"/><circle cx="12" cy="14" r="2"/><path d="M14 4v4H8V4"/></svg>
                </button>
                <button type="button" class="inline-flex h-5 w-5 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none">
                  <svg @click="this.deleteFile(file._id)" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="23" height="23" viewBox="0 0 24 24" stroke-width="2" stroke="#991b1b" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="4" y1="7" x2="20" y2="7"></line>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button v-if="typeof this.files != 'undefined' && this.files.length > 0 && this.files.length <= this.filesTotalSearch" @click="this.getFiles(true, this.files.length - this.breaks, 50)" type="button" class="flex items-center mx-auto my-6 rounded-md border border-transparent bg-slate-100 px-4 py-2 text-base font-medium text-slate-700 hover:bg-slate-200 focus:outline-none">Mehr laden</button>
  </div>
</template>

<script>
import axios from 'axios';
import { useDropzone } from "vue3-dropzone";
import createFileList from 'create-file-list';
import Datepicker from '@vuepic/vue-datepicker';
//import '@vuepic/vue-datepicker/dist/main.css'
import { mapSeries } from 'modern-async'
import './../assets/js/tui-image-editor/dist/tui-image-editor.css';
const ImageEditor = require('./../assets/js/tui-image-editor/dist/tui-image-editor.js');
//import { getCurrentInstance } from 'vue';
//import path from "path";

export default {
  name: 'MediaCenter',
  components: {
    Datepicker
  },
  setup() {
    let oldFileList;

    const onDrop = (fileList) => {
      console.log(fileList);
      let dropinput = document.getElementById('dropzoneinput');
      dropinput.files = createFileList([...fileList]);
      console.log(dropinput);
      if(oldFileList == fileList) {
        return;
      } else {
        dropinput.dispatchEvent(new Event('change'));
        oldFileList = fileList;
      }
    }
    let { getRootProps, isDragActive } = useDropzone({ onDrop, preventDropOnDocument: true, multiple: true});
    return {
      getRootProps,
      isDragActive
    }
  },
  props: {
    site: String
  },
  data() {
    return {
      unlock: false,
      formSent: false,
      allowedFiles: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],
      maxFilesize: 10485760,
      imageEditorActive: false,
      filesearch: {
        term: '',
        timestamp: + new Date(),
        daterange: [],
      },
      memoryUsed: 0,
      files: [],
      filesTotal: 0,
      filesTotalSearch: 0,
      fileTypes: [],
      breakKey: [],
      breaks: 0,
      countBreaks: 0,
      instance: null,
      validationMessage: '<span class="mt-1">Maximal 12 Dateien mit jeweils bis zu 7MB gleichzeitig. Erlaubte Typen: [jpg], [png], [gif], [pdf], [docx]</span>',
      validationError: false,
      uploadSuccess: false,
      uploadPercentage: 0,
      currentlyUploading: false,
      currentFilesForUpload: 0,
      uploadedFiles: [],
      uploadDays: [],
      uploadDates : [],
      minUploadCount: 1,
      maxUploadCount: 1,
      file: '',
      lastSearchCall: 0,
      lastTimestamp: [+ new Date('1999-01-01')],
      timeoutObj: undefined,
      monthNames: [
        'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli',
        'August', 'September', 'Oktober', 'November', 'Dezember'
      ]
    }
  },
  computed: {
    getFileType() {
      return this.site == 'backend-ownpics' ? 'intern' : 'extern';
    },
  },
  methods: {
    getColorByPerc(perc) {
      if(perc < 25) { return 50 }
      if(perc < 50) { return 100 }
      if(perc < 75) { return 200 }
      if(perc <= 100) { return 300 }
    },
    getDayClass(date) {
      let obj = this.uploadDates.find((obj) => {
        if(+ new Date(new Date(obj.date).setHours(0,0,0,0)) == + new Date(new Date(date).setHours(0,0,0,0))) {
          return obj;
        }
      });
      return typeof obj != 'undefined' ? 'bg-pink-' + this.getColorByPerc(Math.round((obj.count / this.maxUploadCount) * 100)) : '';
    },
    formatDate(date) {
      return String(date.getDayName()) + ', ' + String(date.getDate()).padStart(2, '0') + '.' + String(date.getMonth() + 1).padStart(2, '0') + '.' + String(date.getFullYear()).slice(-2) + ' ' + String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0');
    },
    formatDateRange(datesArray) {
      return String(new Date(datesArray[0]).getDate()) + '.' + String(new Date(datesArray[0]).getMonth() + 1) + '.' + String(new Date(datesArray[0]).getFullYear()).slice(-2) + ' - ' + String(new Date(datesArray[1]).getDate()) + '.' + String(new Date(datesArray[1]).getMonth() + 1) + '.' + String(new Date(datesArray[1]).getFullYear()).slice(-2);
    },
    isNewMonth(file, length, key, resolve) {
      if(this.lastTimestamp.length == 0) { this.lastTimestamp = [+ new Date('1999-01-01')]; }
      console.log((new Date(file.createdAt).getMonth() + new Date(file.createdAt).getFullYear()) - ( new Date(this.lastTimestamp.at(-1)).getMonth() + new Date(this.lastTimestamp.at(-1)).getFullYear()));
      let MonthChange = (new Date(file.createdAt).getMonth() + new Date(file.createdAt).getFullYear()) - ( new Date(this.lastTimestamp.at(-1)).getMonth() + new Date(this.lastTimestamp.at(-1)).getFullYear());
      if(MonthChange != 0) {
        console.log('inthere');
        file.newMonth = true;
        this.breakKey.push(key);
        this.countBreaks++;
        this.lastTimestamp[key] = + new Date(file.createdAt);
        console.log(this.lastTimestamp);
      } else {
        file.newMonth = false;
      }
      if(length == (key + 1)) {
        resolve();
      }
    },
    renderMonth(timestamp) {
      return this.monthNames[new Date(timestamp).getMonth()] + ' ' + new Date(timestamp).getFullYear();
    },
    keyUpSearch() {
      if (((+ new Date()) - this.lastSearchCall) <= 750) {
        clearTimeout(this.timeoutObj);
      } else {
        this.lastSearchCall = + new Date();
      }
      this.timeoutObj = setTimeout(() => {
        this.getFiles(false, 0, 50);
      }, 750);
    },
    dragOverHandler(e) {
      console.log('File(s) in drop zone');
      e.preventDefault(e);
    },
    dropHandler(e) {
      e.preventDefault();
      console.log(e);
    },
    deleteFile(fileID) {
      axios
          .get("/fileserver/delete?fileID=" + fileID)
          .then(() => {
            this.getFiles(false, 0, 50);
            this.filesTotal += -1;
          })
          .catch((error) => {
            console.log(false, error.response.data);
          });
    },
    saveFile(fileID, fileData) {
      axios
          .post("/fileserver/save", { fileID: fileID, fileData: fileData})
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(false, error.response.data);
          });
    },
    getTotalCountOfFiles() {
      axios
          .get("/fileserver/total?status=" + this.getFileType)
          .then((res) => {
            console.log(res);
            this.filesTotal = res.data;
          })
          .catch((error) => {
            console.log(false, error.response.data);
          });
    },
    getUploadDates() {
      axios
          .get("/fileserver/uploaddates?status=" + this.getFileType)
          .then((res) => {
            let mapData = async function(uploadDays) {
              return new Promise( (resolve) => {
                let x = async(uploadDays) => {
                  function dayToDate(year, day) {
                    const date = new Date(year, 0, day);
                    return date;
                  }
                  return await mapSeries(uploadDays, async(v) => {
                    return {date: dayToDate(v.data.year, v.data.doy), count: v.count};
                  });
                }
                x(uploadDays).then((upDa) => {
                  console.log(upDa);
                  let sortDates = async function(upDa) {
                    return await upDa.sort(function(a,b) {
                      return new Date(a.date) - new Date(b.date);
                    });
                  }
                  sortDates(upDa).then((upDas) => {
                    console.log(upDas);
                    let renderDates = (upDas) => {
                      if(typeof upDas[0] != 'undefined') {
                        return [
                          +new Date(new Date( new Date(new Date(new Date(upDas[0].date).setHours(0, 0, 0))).setDate(new Date(upDas[0].date).getDate() - 1)) - new Date( new Date( new Date(new Date(upDas[0].date).setHours(0, 0, 0)).setDate(new Date(upDas[0].date).getDate() - 1))).getTimezoneOffset() * 60 * 1000),
                          +new Date(new Date( new Date(new Date(new Date(upDas[upDas.length - 1].date).setHours(23, 59, 59))).setDate(new Date(upDas[upDas.length - 1].date).getDate() + 1)) - new Date( new Date(new Date( new Date(upDas[upDas.length - 1].date).setHours(23, 59, 59)).setDate(new Date(upDas[upDas.length - 1].date).getDate() + 1))).getTimezoneOffset() * 60 * 1000),
                        ];
                      } else {
                        return [
                          + new Date(),
                          + new Date()
                        ]
                      }
                    }
                    let daterange =  renderDates(upDa);
                    console.log(daterange);
                    resolve([daterange, upDa]);
                    return [daterange, upDa];
                  });
                });
              });
            }
            mapData(res.data).then((data) => {
              this.uploadDates = data[1];
              this.maxUploadCount = 1;
              this.uploadDates.forEach((ind) => {
                if(ind.count > this.maxUploadCount) {
                  this.maxUploadCount = ind.count;
                }
              });
              this.filesearch.daterange = data[0];
              console.log(data);
              this.getFiles(false, 0, 50);
              this.unlock = true;
            });
          })
          .catch((error) => {
            console.log(false, error.response.data);
          });
    },
    getTotalMemoryUsage() {
      axios
          .get("/fileserver/totalMemory?status=" + this.getFileType)
          .then((res) => {
            this.memoryUsed = res.data;
          })
          .catch((error) => {
            console.log(false, error.response.data);
          });
    },
    filesPopIn(files) {
      files.forEach((file, ind) => {
        setTimeout(() => {
          this.files.push(file);
        }, (ind * 100));
      });
    },
    getFiles(infinity, skipIndex, limit) {
      console.log(this.filesearch.timestamp);
      axios
          .get('/fileserver/search?term=' + this.filesearch.term + '&timestamp=' + this.filesearch.daterange + '&status=' + this.getFileType + '&skipIndex=' + (typeof skipIndex == 'undefined' ? '0' : skipIndex) + '&limit=' + (typeof limit == 'undefined' ? '12' : limit))
          .then((res) => {
            console.log(res.data);
            this.lastTimestamp = [];
            this.breakKey = [];
            let loadMonthData = () => {
              return {
                doAsync: async () => {
                  return new Promise((resolve) => {
                    if(res.data.length > 0) {
                      res.data.forEach((item, key) => {
                        this.isNewMonth(item, res.data.length, key, resolve);
                      });
                    } else {
                      resolve();
                    }
                  });
                },
              }
            }
            let instance = loadMonthData();
            instance.doAsync().then(() => {
              console.log(res.data);
              let counter = 0;
              res.data.forEach((ind, key) => {
                console.log(res.data[key + counter]);
                if(res.data[key + counter].newMonth == true) {
                  console.log(res.data[key + counter]);
                  let flexBreak = Object.create({ break: 'break', _id: 'break', breakContent: this.renderMonth(res.data[key + counter].createdAt) });
                  res.data.splice((key + counter), 0, flexBreak);
                  counter++;
                  this.breaks = counter;
                }
              });
              if(infinity) {
                this.filesPopIn(res.data);
              } else {
                this.files = res.data;
              }
              console.log(res.data);
              if(res.data.length != 0) {
                console.log(res.data.at(1), res.data.at(0));
                this.filesTotalSearch = Object.keys(res.data.at(0)).length == 0 ? res.data.at(1).meta.count.total : res.data.at(0).meta.count.total;
              }
            });
          })
          .catch((error) => {
            console.log(false, error);
          });
    },
    renderFileDate(timestamp) {
      let date = new Date(timestamp);
      let zeroPad = (num) => String(num).padStart(2, '0');
      return zeroPad(date.getDate()) + '.' + zeroPad(date.getMonth() + 1) + '.' + date.getFullYear().toString().substring(2) + ' | ' + zeroPad(date.getHours()) + ':' + zeroPad(date.getMinutes()) + ' Uhr';
    },
    calcSize(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    initImageEditor(imageURL, fileID, fileName, index) {
      this.imageEditorActive = true;
      //this.editMode.status = true;
      setTimeout(() => {
        this.instance = new ImageEditor(
            document.querySelector("#tui-image-editor"),
            {
              includeUI: {
                loadImage: {
                  path: imageURL + '?' + (Math.random() + 1).toString(36).substring(7),
                  name: fileName
                },
                initMenu: 'crop',
                menuBarPosition: "bottom",
                menu: ['crop', 'flip', 'rotate', 'filter']
              },
              selectionStyle: {
                cornerSize: 20,
                rotatingPointOffset: 10,
              },
              usageStatistics: false
            }
        );
        setTimeout(() => {
          this.instance.on('saveFile', (data) => {
            console.log(data);
            const formData = new FormData();
            formData.append('status', this.getFileType);
            formData.append('fileID', fileID);
            formData.append('files[]', data);
            console.log(formData);
            axios.post('/fileserver/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then((resolve) => {
              setTimeout(() => {
                resolve.data.updated = (Math.random() + 1).toString(36).substring(7);
                this.files[index] = resolve.data;
                this.imageEditorActive = false;
              }, 250);
            })
          });
        }, 500);
        console.log(this.instance);
      }, 250);
    },
    async submitFiles(event) {
      console.log('Fired');
      let files = event.target.files;
      this.currentFilesForUpload = files.length;
      await this.fileValidation(files, this.maxFilesize, this.allowedFiles, this.calcSize).then((filesValid) => {
        let isValid = true;
        for (var i = 0, len = filesValid.length; i < len; i++) {
          !filesValid[i].status ? isValid = false : '';
        }
        console.log('HERE', isValid);
        if(!isValid) {
          console.log(filesValid);
          this.validationMessage = filesValid.length > 1 ? filesValid.reduce((x,y) => !y.status ? '<span class="mt-1">' + y.message + '</span>' : '') : filesValid[0].message;
          this.validationError = true;
          setTimeout(() => {
            this.validationMessage = '<span class="mt-1">Maximal 12 Dateien mit jeweils bis zu 7MB gleichzeitig. Erlaubte Typen: [jpg], [png], [gif], [pdf], [docx]</span>';
            this.validationError = false;
          }, 5000);
          return filesValid;
        } else {
          const formData = new FormData();
          formData.append('status', this.getFileType);
          Object.keys(files).forEach(( key ) => {
            if (files[key] instanceof FileList) {
              formData.append('files[]', files[key][0], files[key][0].name);
            } else {
              formData.append('files[]', files[key]);
            }
          });
          // formData.append('customerID', this.customerID);
          console.log(files);
          const onUploadProgress = (event) => {
            const percentage = Math.round((100 * event.loaded) / event.total);
            this.uploadPercentage = percentage;
          };
          this.currentlyUploading = true;
          this.filesearch.term = '';
          this.filesearch.timestamp = + new Date();
          this.getFiles(false, 0, 50);
          try {
            axios.post('/fileserver/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              onUploadProgress
            })
                .then(resolve => {
                  // console.log(resolve);
                  this.message = 'Uploaded!';
                  this.uploadSuccess = true;
                  console.log(resolve);
                  this.filesTotalSearch += resolve.data.files.length;
                  resolve.data.files.forEach((file, ind) => {
                    file.isnew = true;
                    file.pid = (Math.random() + 1).toString(36).substring(2);
                    setTimeout(() => {
                      this.files.unshift(file);
                      this.files.length >= 12 ? this.files.pop() : '';
                    }, (ind + 1 * 1000));
                  });
                  setTimeout(() => {
                    this.uploadSuccess = false;
                  }, 4500);
                  setTimeout(() => {
                    this.uploadPercentage = 0;
                    this.currentlyUploading = false;
                  }, 1000);
                });
          }
          catch(err) {
            console.log(err);
            this.currentlyUploading = false;
            this.message = 'Something went wrong!';
          }
        }
      });
    },
    fileValidation(files, maxFilesize, allowedFiles, calcSize) {
      return new Promise(function(resolve, reject) {
        if(files.length > 0) {
          if(files.length > 12) {
            resolve([{
              status: false,
              message: 'Du kannst maximal 12 Dateien gleichzeitig hochladen'
            }]);
          }
          let isValid = [];
          Array.prototype.slice.call(files).forEach((file, key) => {
            isValid[key] = {};
            isValid[key].status = true;

            if (file.size > maxFilesize) {
              isValid[key].status = false;
              isValid[key].state = 'error';
              isValid[key].message = 'Die Datei "' + file.title + '" ist zu groß (' + calcSize(file.size) + ')';
            }

            if (!allowedFiles.includes(file.type)) {
              isValid[key].status = false;
              isValid[key].state = 'error';
              isValid[key].message = 'Dieses Dateiformat wird nicht unterstützt (' + file.type + ')';
            }
            if(files.length == key + 1) {
              resolve(isValid);
            }
          });
        } else {
          reject({ status: false, message: 'Keine Dateien erhalten'});
        }
      });
    }
  },
  mounted() {
    this.getUploadDates();
    this.getTotalCountOfFiles();
    this.getTotalMemoryUsage();
  },
  watch: {
    site() {
      this.getUploadDates();
      this.getTotalCountOfFiles();
      this.getTotalMemoryUsage();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.tui-image-editor-range {
  width: 225px !important;
}
.tui-image-editor-wrap {
  bottom: 150px !important;
}
.dp__range_between {
  &.bg-pink-50 { background: #fdf2f8; }
  &.bg-pink-100 { background: #fce7f3; }
  &.bg-pink-200 { background: #fbcfe8; }
  &.bg-pink-300 { background: #fda4af; }
}
.dp__cell_offset {
  color: #888;
}
.inputwrap {
>* {
  display: none !important;
&.trueinput {
  display: inline-block !important;
 }
}
}
.break {
  flex-basis: 100%;
}
.filetile {
  -webkit-animation:pop-in 150ms;
  -moz-animation:pop-in 150ms;
  -ms-animation:pop-in 150ms;
}
@-webkit-keyframes pop-in {
  0% { opacity: 0; -webkit-transform: scale(0.5); }
  100% { opacity: 1; -webkit-transform: scale(1); }
}
@-moz-keyframes pop-in {
  0% { opacity: 0; -moz-transform: scale(0.5); }
  100% { opacity: 1; -moz-transform: scale(1); }
}
@keyframes pop-in {
  0% { opacity: 0; transform: scale(0.5); }
  100% { opacity: 1; transform: scale(1); }
}
</style>
